import React from "react";
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import './App.css';
// import Navbar from "./components/Navbar/Navbar";
// import BGvideoComp from "./components/BGvideoComp/BGvideoComp";
// import Carousel from "./components/Carousel/Carousel";
// import Services from "./components/Services/Services";
// // import TechComponent from "./components/Technologies/TechComp/TechComponent";
// import List from "./components/ListContainer/ListContainer";
// // import CompanyCarousel from "./components/CompanyCarousel/CompanyCarousel";
// // import Reviews from "./components/Reviews/Reviews/Reviews";
// // import BlogCard from "./components/CardContainer/Card";
// import ContactUs from "./components/ContactUs/ContactUs";
// import Footer from "./components/Footer/Footer";
import HomePage from "./pages/HomePage";
// import { blogData } from "./constants/blogData";
// import { ServiceData } from "./constants/data";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Disclaimer from "./pages/Disclaimer";
import TermsConditions from "./pages/TermsConditions";
import ReturnPolicy from "./pages/ReturnPolicy";

// function App() {
  // const [cardData] = useState(blogData);

  window.addEventListener('scroll', () => {
    document.body.style.setProperty('--scroll', window.pageYOffset / (document.body.offsetHeight - window.innerHeight));
  }, false);

  const router = createBrowserRouter([
    {
      path: '/', 
      element: <HomePage />,
      // errorElement: <ErrorPage />,
      // children: routerOptions
    },
    {
      path: '/privacy',
      element: <PrivacyPolicy />,
      // errorElement: <ErrorPage />,
    },
    {
      path: '/disclaimer',
      element: <Disclaimer />
    },
    {
      path: '/terms-and-conditions',
      element: <TermsConditions />
    },
    {
      path: '/refund',
      element: <ReturnPolicy />
    },
    {
      path: '/*',
      element: <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '80vh'}}>
        <div style={{fontSize: '1.4rem'}}>404 | Page Not Found</div>
      </div>
    }
  ]);

  const App = () => {
    return <RouterProvider router={router} />;
  };

  // return (
  //   <>
  //     <Navbar />
  //     <div id="home">
  //       <BGvideoComp />
  //     </div>
  //     <div id="services">
  //       <Services />
  //     </div>
  //     <div id="industries">
  //       <Carousel />
  //     </div>

  //     {/* <div>
  //       <TechComponent />
  //     </div> */}
  //     <div style={{ background: "white", zIndex: 1 }} id="process">
  //       <List />
  //     </div>
  //     {/* <Reviews /> */}
  //     {/* <div style={{ display: "flex", justifyContent: "center", padding: "5rem 0rem", background: "white" }} id="blogs">
  //       <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "center" }}>
  //     {/* <div>
  //       <CompanyCarousel details={ServiceData[0]} />
  //     </div> */}
  //     {/* <div id="reviews">
  //       <Reviews />
  //     </div> */}
  //     {/* <div style={{ display: "flex", justifyContent: "center", padding: "5rem 0rem", background:"white" }} id="blogs">
  //       <div style={{ display: "flex",flexWrap: "wrap", justifyContent: "center" }}>
  //         {cardData.map((card, index) => (
  //           <BlogCard details={card} key={index} />
  //         ))}
  //       </div>
  //     </div> */}
  //     <div style={{ padding: "5rem 0", backgroundColor: "rgb(246, 247, 250)" }} id="contact-us">
  //       {/* //backgroundColor: "rgb(246, 247, 250)" */}
  //       <ContactUs />
  //     </div>
  //     <div>
  //       <Footer />
  //     </div>
  //   </>
  // );
// }

export default App;
