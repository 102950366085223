import React, { useState, useEffect } from "react";
import "./ListContainer.scss";
import { Divider } from "@mui/material";
import { listDummyData } from "../../constants/data.js";
import Button from "../common/Button/Button";
import Aos from "aos";
import "aos/dist/aos.css";

const ListContainer3 = () => {
  const [heading] = useState(
    "Our Process"
  );
  const [subHeading] = useState(
    "We help our clients achieve tangible, high-impact results."
  );
  const [btnText] = useState("Schedule a Live Demo");
  const [listData] = useState(listDummyData);

  // const fetchCompDetails = async () => {
  //   console.log("Heading : ", heading);
  // };

  useEffect(() => {
    Aos.init({ duration: 550, once: true });
    // fetchCompDetails();
  });

  const dividerStyle = {
    marginRight: "50%",
    height: "60%",
    borderColor: "rgba(0,0,0,0.24)",
  };

  return (
    <>
    <div className="list-main-container">
      <div className="head-container">
        <div className="text-container3" data-aos="fade-up">
          <div className="acc-container">
            <h2>{heading}</h2>
          </div>
          <div className="wehelpcontainer">{subHeading}</div>
          <div className="btn-container">
            <a href="#contact-us">
              <Button title={btnText} />
            </a>
          </div>
          <div className="img-container"> <img src="Images/development.png" alt="process" /></div>
        </div>
        <div className="main-container3">
          {listData.map((curelm, index) => {
            return (
              <div className="item-container3" key={index} data-aos="fade-up">
                <div className="left-container3">
                  <div className="series-container">
                    <p className="number">{curelm.number}</p>
                  </div>
                  {listData.length - 1 !== index && (
                    <Divider
                      style={dividerStyle}
                      orientation="vertical"
                      variant="middle"
                      flexItem
                    />
                  )}
                </div>
                <div className="right-item-container3">
                  <div className="heading3">
                    <h3>{curelm.heading}</h3>
                  </div>
                  <div className="r-content-container">
                    <p className="content">{curelm.content}</p>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      </div>
    </>
  );
};

export default ListContainer3;
