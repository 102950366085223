import React, { useEffect } from "react";
import { useLocation } from 'react-router-dom';
import Navbar from "../components/Navbar/Navbar";
import BGvideoComp from "../components/BGvideoComp/BGvideoComp";
import Carousel from "../components/Carousel/Carousel";
import Services from "../components/Services/Services";
// import TechComponent from "./components/Technologies/TechComp/TechComponent";
import List from "../components/ListContainer/ListContainer";
// import CompanyCarousel from "./components/CompanyCarousel/CompanyCarousel";
// import Reviews from "./components/Reviews/Reviews/Reviews";
// import BlogCard from "./components/CardContainer/Card";
import ContactUs from "../components/ContactUs/ContactUs";
import Footer from "../components/Footer/Footer";

const HomePage = () => {
  const location = useLocation();

  const scrollToElement = (elementId) => {
    const element = document.getElementById(elementId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };
  

  useEffect(() => {
    if (location.hash) {
      const hash = location.hash.replace('#', '');
      scrollToElement(hash);
    }
  }, [location, location.hash]); // Re-run the effect if the hash changes

  return (<>
        <Navbar />
        <div id="home">
          <BGvideoComp />
        </div>
        <div id="services">
          <Services />
        </div>
        <div id="industries">
          <Carousel />
        </div>
  
        {/* <div>
          <TechComponent />
        </div> */}
        <div style={{ background: "white", zIndex: 1 }} id="process">
          <List />
        </div>
        {/* <Reviews /> */}
        {/* <div style={{ display: "flex", justifyContent: "center", padding: "5rem 0rem", background: "white" }} id="blogs">
          <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "center" }}>
        {/* <div>
          <CompanyCarousel details={ServiceData[0]} />
        </div> */}
        {/* <div id="reviews">
          <Reviews />
        </div> */}
        {/* <div style={{ display: "flex", justifyContent: "center", padding: "5rem 0rem", background:"white" }} id="blogs">
          <div style={{ display: "flex",flexWrap: "wrap", justifyContent: "center" }}>
            {cardData.map((card, index) => (
              <BlogCard details={card} key={index} />
            ))}
          </div>
        </div> */}
        <div style={{ padding: "5rem 0", backgroundColor: "rgb(246, 247, 250)" }} id="contact-us">
          {/* //backgroundColor: "rgb(246, 247, 250)" */}
          <ContactUs />
        </div>
        <div>
          <Footer />
        </div>
      </>)
}

export default HomePage;