import React, { useState, useEffect } from "react";
import "./ContactUs.scss";
import {
  TextField,
  Select,
  InputLabel,
  FormControl,
  TextareaAutosize,
} from "@mui/material";
import axios from "axios";
import Button from "../common/Button/Button";
import { services } from "../../constants/data";
import MenuItem from "@mui/material/MenuItem";
import Snackbar from '@mui/material/Snackbar';
import AlertTitle from '@mui/material/AlertTitle';
import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Form = () => {
  const [inputData] = useState(services);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [service, setService] = useState("");
  const [requirements, setRequirements] = useState("");
  const [open, setOpen] = React.useState(false);
  const [success, setSuccess] = useState(false);
  const [btnDisabled, setBtnDisabled] = useState(true);

  // const handleClick = () => {
  //   setOpen(true);
  // };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const handleChange = (event) => {
    setService(event.target.value);
  };

  const onContactUsSubmit = async () => {
    const contactUsUrl = "https://api.digitizereal.com/api/v1/contactus/";
    // const msg = "Your query has reached us successfully. We will contact you soon.";
    try {
      const body = {
        name,
        phone,
        email,
        query: requirements,
        queryType: service,
      };
      const response = await axios.post(contactUsUrl, body);
      if (response.data.success || response.status === 201) {
        setSuccess(true);
        setName("");
        setEmail("");
        setPhone("");
        setRequirements("");
        setService("");
        setOpen(true);
      }
    } catch (e) {
      console.log("Error : ", e);
      setSuccess(false);
      setOpen(true);
    }
  };

  useEffect(() => {
    if (name && email && service && requirements && (phone > 5999999999)) {
      setBtnDisabled(false);
    } else {
      setBtnDisabled(true);
    }
  }, [name, email, service, requirements, phone]);

  return (
    <>
      <div className="main-form-container">
        <div className="content-container">
          <div className="title-container">
            <div className="title">Get in Touch!</div>
            <div className="title">We’d Love to Hear From You.</div>
            <div className="para">
              We are here to answer any questions you may have about
              <span style={{ fontWeight: "bold", marginLeft: "0.2rem" }}>
                Digitize
                <span style={{ color: "red" }}>Real</span>
              </span>
              . Reach out to us and we will get back as soon as possible.
            </div>
          </div>
          <div className="form-container">
            <div className="form-fields-container">
              <div className="feild-container">
                <TextField
                  // error
                  className="textinfocontainer"
                  id="standard-basic"
                  label="Name"
                  variant="standard"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  inputProps={{
                    maxLength: 30,
                  }}
                />
              </div>
              <div className="feild-container">
                <TextField
                  // error
                  className="textinfocontainer"
                  id="standard-basic"
                  label="Email"
                  variant="standard"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  inputProps={{
                    maxLength: 40,
                  }}
                />
              </div>
              <div className="feild-container">
              <TextField
                  // error
                  className="textinfocontainer"
                  id="standard-basic"
                  label="Contact Number"
                  variant="standard"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  inputProps={{
                    maxLength: 10,
                  }}
                />
              </div>
              <div className="feild-container">
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Services
                  </InputLabel>
                  <Select
                    // error
                    id="outlined-select-currency-native"
                    label="Services"
                    className="selectfield"
                    fullWidth={true}
                    value={service}
                    onChange={handleChange}
                  >
                    {inputData.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <div className="feild-container">
                <FormControl fullWidth>
                  <TextareaAutosize
                    // error
                    className="textfields"
                    fullWidth
                    placeholder="Requirements"
                    id="fullWidth"
                    minRows={5}
                    value={requirements}
                    onChange={(e) => setRequirements(e.target.value)}
                    maxRows={5}
                  />
                </FormControl>
              </div>
              <div className="btn-container">
                <Button title="Send Message" disabled={btnDisabled} onClickHandler={onContactUsSubmit} />
              </div>
            </div>
          </div>
        </div>
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
          <Alert onClose={handleClose} severity={success ? "info" : "error"} sx={{ width: '100%' }}>
            <AlertTitle>{success ? "Success" : "Failure"}</AlertTitle>
            {success ? "Your query has reached us successfully. We will contact you soon!" : "Something went wrong! Please try again later."}
          </Alert>
        </Snackbar>
      </div>
    </>
  );
};

export default Form;
