import React from "react";
import Footer from "../components/Footer/Footer";
import Navbar from "../components/Navbar/Navbar";
import disclaimerContent from "../constants/disclaimer";
import "../constants/styles.scss";

const DisclaimerSection = ({ title, content, listWithTitle, listWithoutTitle, footer }) => (
  <div className="privacy-policy-section" style={{
    margin: '2rem 0',
    color: 'grey'
  }}>
    <h2 style={{
        margin: '0.7rem 0',
        color: 'crimson',
        fontWeight: 'normal'
    }}>{title}</h2>
    <p style={{
        lineHeight: '1.6rem'
    }}>{content}</p>
    {listWithTitle && <ul style={{}}>
        {listWithTitle.map(listItem => (<li style={{margin: '0.5rem 1.4rem'}}>
            <div style={{margin: '0.5rem 0'}}><span style={{fontWeight: 'bold', marginRight: '0.3rem'}}>{listItem.title}: </span><span>{listItem.description}</span></div>
        </li>))}
    </ul>}
    {listWithoutTitle && (<ul>
        {listWithoutTitle.map(listItem => <li style={{margin: '0.5rem 1.4rem'}}>{listItem}</li>)}
    </ul>)}
    {footer && <div className="footerContent" style={{margin: '1.5rem 0'}}>{footer}</div>}
  </div>
);

const Disclaimer = () => (
    <div className="authorizationContainer">
        <Navbar />
        <div className="mainContent" >
            <div style={{display: 'flex', justifyContent: 'center'}}>
                <h1>Disclaimer</h1>
            </div>
            {disclaimerContent.map((section) => (
                <DisclaimerSection 
                    key={section.title} 
                    title={section.title} 
                    content={section.content} 
                    listWithTitle={section.listWithTitle}
                    listWithoutTitle={section.listWithoutTitle}
                    footer={section.footer} />
            ))}
        </div>
        <Footer />
    </div>
);

export default Disclaimer;