const termsAndConditionsContent = [
    {
      title: 'Introduction',
      content: "Welcome to DigitizeReal Technologies Private Limited ('we', 'us', or 'our'). These terms of use ('Terms', 'Agreement') are an agreement between DigitizeReal Technologies Private Limited and you ('User', 'you' or 'your'). This Agreement sets forth the general terms of use of the digitizereal.com website and any of its products or services (collectively, 'Website' or 'Services')."
    },
    {
      title: 'Accounts and Membership',
      content: 'If you create an account on the Website, you are responsible for maintaining the security of your account and you are fully responsible for all activities that occur under the account and any other actions taken in connection with it. We may, but have no obligation to, monitor and review new accounts before you may sign in and start using the Services. Providing false contact information of any kind may result in the termination of your account. You must immediately notify us of any unauthorized uses of your account or any other breaches of security. We will not be liable for any acts or omissions by you, including any damages of any kind incurred as a result of such acts or omissions. We may suspend, disable, or delete your account (or any part thereof) if we determine that you have violated any provision of this Agreement or that your conduct or content would tend to damage our reputation and goodwill. If we delete your account for the foregoing reasons, you may not re-register for our Services. We may block your email address and Internet Protocol address to prevent further registration.'
    },
    {
      title: 'User Content',
      content: "We do not own any data, information, or material ('Content') that you submit on the Website in the course of using the Service. You shall have sole responsibility for the accuracy, quality, integrity, legality, reliability, appropriateness, and intellectual property ownership or right to use of all submitted Content. We may monitor and review Content on the Website submitted or created using our Services by you. Unless specifically permitted by you, your use of the Website does not grant us the license to use, reproduce, adapt, modify, publish, or distribute the Content created by you or stored in your user account for commercial, marketing, or any similar purpose. But you grant us permission to access, copy, distribute, store, transmit, reformat, display, and perform the Content of your user account solely as required for the purpose of providing the Services to you. Without limiting any of those representations or warranties, we have the right, though not the obligation, to, in our own sole discretion, refuse or remove any Content that, in our reasonable opinion, violates any of our policies or is in any way harmful or objectionable."
    },
    {
      title: 'Billing and Payments',
      content: 'You shall pay all fees or charges to your account in accordance with the fees, charges, and billing terms in effect at the time a fee or charge is due and payable. If, in our judgment, your purchase constitutes a high-risk transaction, we will require you to provide us with a copy of your valid government-issued photo identification, and possibly a copy of a recent bank statement for the credit or debit card used for the purchase. We reserve the right to change products and product pricing at any time. We also reserve the right to refuse any order you place with us. We may, in our sole discretion, limit or cancel quantities purchased per person, per household, or per order. These restrictions may include orders placed by or under the same customer account, the same credit card, and/or orders that use the same billing and/or shipping address. In the event that we make a change to or cancel an order, we may attempt to notify you by contacting the email and/or billing address/phone number provided at the time the order was made.'
    },
    {
      title: 'Accuracy of Information',
      content: 'Occasionally there may be information on the Website that contains typographical errors, inaccuracies, or omissions that may relate to product descriptions, pricing, availability, promotions, and offers. We reserve the right to correct any errors, inaccuracies, or omissions, and to change or update information or cancel orders if any information on the Website or Services is inaccurate at any time without prior notice (including after you have submitted your order). We undertake no obligation to update, amend or clarify information on the Website including, without limitation, pricing information, except as required by law. No specified update or refresh date applied on the Website should be taken to indicate that all information on the Website or Services has been modified or updated.'
    },
    {
      title: 'Third-Party Services',
      content: 'If you decide to enable, access, or use third-party services, be advised that your access and use of such other services are governed solely by the terms of use of such other services, and we do not endorse, are not responsible or liable for, and make no representations as to any aspect of such other services, including, without limitation, their content or the manner in which they handle data (including your data) or any interaction between you and the provider of such other services. You irrevocably waive any claim against DigitizeReal Technologies Private Limited with respect to such other services. DigitizeReal Technologies Private Limited is not liable for any damage or loss caused or alleged to be caused by or in connection with your enablement, access, or use of any such other services, or your reliance on the privacy practices, data security processes, or other policies of such other services. You may be required to register for or log into such other services on their respective platforms. By enabling any other services, you are expressly permitting DigitizeReal Technologies Private Limited to disclose your data as necessary to facilitate the use or enablement of such other service.'
    },
    {
        title: "Backups",
        content: "We are not responsible for the Content residing on the Website. We performs regular backups of the Website and its Content, primarily for disaster recovery purposes. However, these backups are not guaranteed and are not intended as a primary data storage solution. You are solely responsible for maintaining your own backups of your data that you store or access on our Website. While we aim to ensure the integrity and security of our network and systems, we cannot guarantee that our security measures will prevent third-parties from illegally obtaining access to this information. In the event of data loss or a breach of our systems, DigitizeReal Technologies Private Limited will endeavor to restore lost data from the latest backup; however, we cannot guarantee the recovery of any lost data. DigitizeReal Technologies Private Limited disclaims any liability for the loss of data or any consequences thereof."
    },
        {
          "title": "Terms",
          "content": "By accessing the website at https://www.digitizereal.com, you are agreeing to be bound by these terms of service, all applicable laws and regulations, and agree that you are responsible for compliance with any applicable local laws. If you do not agree with any of these terms, you are prohibited from using or accessing this site. The materials contained in this website are protected by applicable copyright and trademark law."
        },
        {
          "title": "Use License",
          "content": "Permission is granted to temporarily download one copy of the materials (information or software) on DigitizeReal Technologies Private Limited's website for personal, non-commercial transitory viewing only. This is the grant of a license, not a transfer of title, and under this license you may not: modify or copy the materials; use the materials for any commercial purpose, or for any public display (commercial or non-commercial); attempt to decompile or reverse engineer any software contained on DigitizeReal Technologies Private Limited's website; remove any copyright or other proprietary notations from the materials; or transfer the materials to another person or \"mirror\" the materials on any other server. This license shall automatically terminate if you violate any of these restrictions and may be terminated by DigitizeReal Technologies Private Limited at any time. Upon terminating your viewing of these materials or upon the termination of this license, you must destroy any downloaded materials in your possession whether in electronic or printed format."
        },
        {
          "title": "Disclaimer",
          "content": "The materials on DigitizeReal Technologies Private Limited's website are provided on an 'as is' basis. DigitizeReal Technologies Private Limited makes no warranties, expressed or implied, and hereby disclaims and negates all other warranties including, without limitation, implied warranties or conditions of merchantability, fitness for a particular purpose, or non-infringement of intellectual property or other violation of rights. Further, DigitizeReal Technologies Private Limited does not warrant or make any representations concerning the accuracy, likely results, or reliability of the use of the materials on its website or otherwise relating to such materials or on any sites linked to this site."
        },
        {
          "title": "Limitations",
          "content": "In no event shall DigitizeReal Technologies Private Limited or its suppliers be liable for any damages (including, without limitation, damages for loss of data or profit, or due to business interruption) arising out of the use or inability to use the materials on DigitizeReal Technologies Private Limited's website, even if DigitizeReal Technologies Private Limited or a DigitizeReal Technologies Private Limited authorized representative has been notified orally or in writing of the possibility of such damage. Because some jurisdictions do not allow limitations on implied warranties, or limitations of liability for consequential or incidental damages, these limitations may not apply to you."
        },
        // {
        //   "title": "Accuracy of Materials",
        //   "content": "The materials appearing on DigitizeReal Technologies Private Limited's website could include technical, typographical, or photographic errors. DigitizeReal Technologies Private Limited does not warrant that any of the materials on its website are accurate, complete, or current. DigitizeReal Technologies Private Limited may make changes to the materials contained on its website at any time without notice. However, DigitizeReal Technologies Private Limited does not make any commitment to update the materials."
        // },
        {
          "title": "Links",
          "content": "DigitizeReal Technologies Private Limited has not reviewed all of the sites linked to its website and is not responsible for the contents of any such linked site. The inclusion of any link does not imply endorsement by DigitizeReal Technologies Private Limited of the site. Use of any such linked website is at the user's own risk."
        },
        {
          "title": "Modifications",
          "content": "DigitizeReal Technologies Private Limited may revise these terms of service for its website at any time without notice. By using this website you are agreeing to be bound by the then current version of these terms of service."
        },
        {
          "title": "Governing Law",
          "content": "These terms and of use are governed by and construed in accordance with the laws of New Delhi, India and you irrevocably submit to the exclusive jurisdiction of the courts in that State or location."
        }
      
];

export default termsAndConditionsContent;