import { emailId, phoneNumber, effectiveDate } from "../constants/data";

const disclaimerContent = [
    {
      title: "General Information",
      content: 'The information provided by DigitizeReal Technologies Private Limited ("we", "us", or "our") on this website is for general informational purposes only. All information on the site is provided in good faith, however, we make no representation or warranty of any kind, express or implied, regarding the accuracy, adequacy, validity, reliability, availability, or completeness of any information on the site.'
    },
    {
      title: "Professional Advice",
      content: 'The site cannot and does not contain professional advice. The information is provided for general informational and educational purposes only and is not a substitute for professional advice. Accordingly, before taking any actions based upon such information, we encourage you to consult with the appropriate professionals. We do not provide any kind of professional advice. The use or reliance of any information contained on this site is solely at your own risk.'
    },
    {
      title: "External Links",
      content: 'The site may contain (or you may be sent through the site) links to other websites or content belonging to or originating from third parties or links to websites and features in banners or other advertising. Such external links are not investigated, monitored, or checked for accuracy, adequacy, validity, reliability, availability, or completeness by us. We do not warrant, endorse, guarantee, or assume responsibility for the accuracy or reliability of any information offered by third-party websites linked through the site or any website or feature linked in any banner or other advertising. We will not be a party to or in any way be responsible for monitoring any transaction between you and third-party providers of products or services.'
    },
    {
      title: "Limitation of Liability",
      content: 'Under no circumstance shall we have any liability to you for any loss or damage of any kind incurred as a result of the use of the site or reliance on any information provided on the site. Your use of the site and your reliance on any information on the site is solely at your own risk. This limitation of liability applies to all damages of any kind, including but not limited to direct, indirect, incidental, punitive, or consequential damages, loss of data, income or profit, loss of or damage to property, and claims of third parties.'
    },
    {
      title: "Indemnity",
      content: "You agree to indemnify, defend, and hold harmless DigitizeReal Technologies Private Limited, its officers, directors, employees, agents, and third parties, for any losses, costs, liabilities, and expenses (including reasonable attorney's fees) relating to or arising out of your use of or inability to use the site or services, any user postings made by you, your violation of any terms of this agreement or your violation of any rights of a third party, or your violation of any applicable laws, rules, or regulations. DigitizeReal Technologies Private Limited reserves the right, at its own cost, to assume the exclusive defense and control of any matter otherwise subject to indemnification by you, in which event you will fully cooperate with DigitizeReal Technologies Private Limited in asserting any available defenses."
    },
    {
      title: "Errors and Omissions",
      content: 'While we have made every attempt to ensure that the information contained in this site has been obtained from reliable sources, DigitizeReal Technologies Private Limited is not responsible for any errors or omissions or for the results obtained from the use of this information. All information in this site is provided "as is", with no guarantee of completeness, accuracy, timeliness, or of the results obtained from the use of this information, and without warranty of any kind, express or implied, including, but not limited to warranties of performance, merchantability, and fitness for a particular purpose.'
    },
    {
      title: "Views Expressed",
      content: 'Any views or opinions represented on the site belong solely to the content creators and do not represent those of people, institutions, or organizations that the DigitizeReal Technologies Private Limited or creators may or may not be associated with in a professional or personal capacity unless explicitly stated. Any views or opinions are not intended to malign any religion, ethnic group, club, organization, company, or individual.'
    },
    {
      title: "No Responsibility",
      content: 'DigitizeReal Technologies Private Limited is not responsible for any content that users post. The opinions expressed in posts are the responsibility of the author and do not necessarily reflect the views of DigitizeReal Technologies Private Limited.'
    },
    {
      title: "Fair Use Notice",
      content: "This site may contain copyrighted material the use of which has not always been specifically authorized by the copyright owner. We are making such material available in our efforts to advance understanding of environmental, political, human rights, economic, democracy, scientific, and social justice issues, etc. We believe this constitutes a 'fair use' of any such copyrighted material as provided for in section 107 of the US Copyright Law. If you wish to use copyrighted material from this site for purposes of your own that go beyond 'fair use', you must obtain permission from the copyright owner."
    },
    {
      title: "No Guarantees",
      content: 'DigitizeReal Technologies Private Limited does not guarantee the accuracy, relevance, timeliness, or completeness of any information on this website or any websites linked to this site.'
    },
    {
      title: "Affiliate Disclosure",
      content: 'Some of the links on this site may be affiliate links. This means if you click on the link and purchase the item, DigitizeReal Technologies Private Limited may receive an affiliate commission at no extra cost to you. Regardless, we only recommend products or services we believe will add value to our readers. All opinions expressed here are our own and are not influenced by compensation.'
    },
    {
      title: "Testimonials",
      content: 'The site may contain testimonials by users of our products and/or services. These testimonials reflect the real-life experiences and opinions of such users. However, the experiences are personal to those particular users and may not necessarily be representative of all users of our products and/or services. We do not claim, and you should not assume, that all users will have the same experiences. Your individual results may vary.'
    },
    {
      title: "Changes to the Disclaimer",
      content: `We may update this Disclaimer from time to time. Any changes will be communicated to you through our website. The effective date of this Disclaimer is ${effectiveDate}.`
    },
    {
      title: "Contact Information",
      content: 'If you have any questions or concerns about this Disclaimer, please contact us at:',  
      listWithTitle: [
        {
            title: 'Email',
            description: emailId
        },
        {
            title: 'Phone',
            description: phoneNumber
        }
      ],
      footer: 'DigitizeReal Technologies Private Limited, Registered in 2022, Delhi, India.'
    }
  ];
  
  export default disclaimerContent;
  