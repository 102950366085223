export const CardData = [
  // {
  //   id: 0,
  //   heading: "Telecom",
  //   description:
  //     "We create systems for managing network operability, assets, information infrastructures, customer relations, and more.",
  //   learnM: "Learn More",
  //   imgurl: "Images/Telecome.jpg",
  //   active: false,
  // },
  {
    id: 0,
    heading: "Fintech",
    description: "We modify enhance and automate financial services for businesses and consumers through mobile banking, automated portfolio managers, peer to peer payment services.",
    learnM: "Learn More",
    imgurls: {
      laptop: "Images/laptop/fintech.jpg",
      mobile: "Images/mobile/fintech.jpg",
      tablet: "Images/laptop/fintech.jpg"
    },
    active: false,
  },
  // {
  //   id: 1,
  //   heading: "Health Care",
  //   description:
  //     "We develop and integrate regulation-compliant digital solutions that cover the entire care delivery continuum.",
  //   learnM: "Learn More",
  //   imgurl: "Images/HealthCare.jpg",
  //   active: false,
  // },
  {
    id: 1,
    heading: "Education",
    description:
      "We aimed at facilitating and enhancing learning. Our IT tools provide more engaging inclusive and individualised learning experience.",
    learnM: "Learn More",
    imgurls: {
      laptop: "Images/laptop/edtech.jpg",
      mobile: "Images/mobile/edtech.jpg",
      tablet: "Images/laptop/edtech.jpg"
    },
    active: false,
  },
  // {
  //   id: 2,
  //   heading: "Retail",
  //   description:
  //     "We automate online and offline retail operations with technologies ready for your growth and market changes.",
  //   learnM: "Learn More",
  //   imgurl: "Images/Retail.jpg",
  //   active: false,
  // },
  {
    id: 2,
    heading: "Travel",
    description:
      "Helps your customers to book trips together with airline tickets, hotel accomodation, car rentals, and mony other travel related activities.",
    learnM: "Learn More",
    imgurls: {
      laptop: "Images/laptop/travel.jpg",
      mobile: "Images/mobile/travel.jpg",
      tablet: "Images/laptop/travel.jpg"
    },
    active: false,
  },
  // {
  //   id: 3,
  //   heading: "Finance",
  //   description:
  //     "Our solutions power all aspects of digital finance, analytics, and more, assuring against fraud and non-compliance.",
  //   learnM: "Learn More",
  //   imgurl: "Images/WallStreet.jpg",
  //   active: false,
  // },
  {
    id: 3,
    heading: "Blockchain",
    description:
      "Blockchain creates a trusted unfilterable, uncensorable repository of data and information that is accessible worldwide this is why blockchain is the future.",
    learnM: "Learn More",
    imgurls: {
      laptop: "Images/laptop/blockchain.jpg",
      mobile: "Images/mobile/blockchain.jpg",
      tablet: "Images/laptop/blockchain.jpg"
    },
    active: false,
  },
  // {
  //   id: 4,
  //   heading: "Automotive",
  //   description:
  //     "From in-vehicle technology to dealership platforms, we offer a range of solutions for all value chain participants.",
  //   learnM: "Learn More",
  //   imgurl: "Images/Automotive.jpg",
  //   active: false,
  // },
  {
    id: 4,
    heading: "E-commerce",
    description:
      "We will help you to create your own e-commerce website and analyse their behaviour. Having your own e-commerce website helps you better understand your customers.",
    learnM: "Learn More",
    imgurls: {
      laptop: "Images/laptop/shopping.jpg",
      mobile: "Images/mobile/shopping.jpg",
      tablet: "Images/laptop/shopping.jpg"
    },
    active: false,
  },
  // {
  //   id: 5,
  //   heading: "Hi-Tech",
  //   description:
  //     "We partner with technology companies to accelerate their RnD, software engineering, and release cycles.",
  //   learnM: "Learn More",
  //   imgurl: "Images/High tech.jpg",
  //   active: false,
  // },
  {
    id: 5,
    heading: "Healthcare",
    description:
      "Lets take healthcare to the next level by using our software solution which will help you to drive in more patient engagement.",
    learnM: "Learn More",
    imgurls: {
      laptop: "Images/laptop/healthcare.jpg",
      mobile: "Images/mobile/healthcare.jpg",
      tablet: "Images/laptop/healthcare.jpg"
    },
    active: false,
  },
  {
    id: 6,
    heading: "Entertainment",
    description:
      "Our apps allow users to have easy functional access to videos to the customer and are optimised for better experience.",
    learnM: "Learn More",
    imgurls: {
      laptop: "Images/laptop/entertainment.jpg",
      mobile: "Images/mobile/entertainment.jpg",
      tablet: "Images/laptop/entertainment.jpg"
    },
    active: false,
  },
  {
    id: 7,
    heading: "Real Estate",
    description:
      "We help your customer buy, rent or sale of residencial and comercial property, check real estate prices, property trends.",
    learnM: "Learn More",
    imgurls: {
      laptop: "Images/laptop/real-estate.jpg",
      mobile: "Images/mobile/real-estate.jpg",
      tablet: "Images/laptop/real-estate.jpg"
    },
    active: false,
  },
  {
    id: 8,
    heading: "Fitness",
    description:
      "Our product provides more support around improving your customer welness by allowing them to track health and fitness achievements from anywhere. Additionally you can offer guidance and provide training regimes from a mobile device.",
    learnM: "Learn More",
    imgurls: {
      laptop: "Images/laptop/fitness.jpg",
      mobile: "Images/mobile/fitness.jpg",
      tablet: "Images/laptop/fitness.jpg"
    },
    active: false,
  },
  {
    id: 9,
    heading: "Business",
    description:
      "Using enterprise mobility solutions, businesses have tremendous opportunities to modernize the operations to defeat challenges, boost productivity, enrich customer services & earn more revenue.",
    learnM: "Learn More",
    imgurls: {
      laptop: "Images/laptop/business.jpg",
      mobile: "Images/mobile/business.jpg",
      tablet: "Images/laptop/business.jpg"
    },
    active: false,
  },
];
