import React from "react";
import "./Button.scss";
const Button = (props) => {
  const { title, onClickHandler, disabled} = props;
  return (
    <>
      <button onClick={onClickHandler} disabled={disabled} className="btn">{title}</button>
    </>
  );
};

export default Button;
