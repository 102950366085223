import { emailId, phoneNumber, effectiveDate } from "./data";

const privacyPolicyContent = [
    {
      title: "Introduction",
      content: 'DigitizeReal Technologies Private Limited ("we", "us", or "our") is committed to protecting your privacy. This Privacy Policy explains how we collect, use, and disclose your personal information in accordance with the Information Technology Act, 2000, and the Information Technology (Reasonable Security Practices and Procedures and Sensitive Personal Data or Information) Rules, 2011. This policy applies to all users of our website and services.'
    },
    {
      title: "Information Collection",
      content: 'We collect various types of personal information, including but not limited to your name, email address, phone number, IP address, and payment information. Information is collected through forms on our website, cookies, and other tracking technologies. The types of personal information we collect include:', 
      listWithTitle: [
        {
            title: 'Contact Information',
            description: 'Name, email address, phone number'
        },
        {
            title: 'Financial Information',
            description: 'Payment details, transaction history'
        },
        {
            title: 'Technical Information',
            description: 'IP address, browser type, operating system'
        },
        {
            title: 'Usage Information',
            description: 'Pages visited, time spent on pages, navigation patterns'
        }
      ]
    },
    {
      title: "Use of Information",
      content: 'We use the collected information to:',
      listWithoutTitle: [
        'Provide and improve our services',
        'Communicate with you regarding your account or transactions',
        'Send you promotional materials and offers',
        'Respond to your inquiries and provide customer support',
        'Analyze website usage and improve our website and services'
      ],
      footer: 'The legal basis for processing your information includes your consent, performance of a contract, and compliance with legal obligations.'
    },
    {
      title: "Data Sharing and Disclosure",
      content: 'We do not share your personal information with third parties except for:',
      listWithTitle: [
        {
            title: 'Service Providers',
            description: 'Who assist in our business operations'
        },
        {
            title: 'Affiliates',
            description: 'For marketing and other purposes'
        },
        {
            title: 'Legal Requirements',
            description: 'To comply with applicable laws or respond to valid legal processes'
        }
      ],
      footer: 'All third-party service providers are contractually obligated to protect your information and use it only for the purposes we specify.'
    },
    {
      title: "Cookies and Tracking Technologies",
      content: 'We use cookies to enhance your experience on our website. Cookies help us understand how you use our site, remember your preferences, and provide personalized content. The types of cookies we use include:',
      listWithTitle: [
        {
            title: 'Essential Cookies',
            description: 'Necessary for basic website functionality',
        },
        {
            title: 'Performance Cookies',
            description: 'To analyze website usage and improve performance',
        },
        {
            title: 'Marketing Cookies',
            description: 'To deliver personalized advertising',
        }

      ],
      footer: 'You can manage your cookie preferences through your browser settings.'
    },
    {
      title: "Data Security",
      content: 'We implement reasonable security practices and procedures to protect your personal information. This includes encryption, access controls, and regular security audits. In the event of a data breach, we will notify you promptly in accordance with applicable laws.',
    },
    {
      title: "Data Retention",
      content: 'We retain your personal information for as long as necessary to fulfill the purposes for which it was collected, comply with legal obligations, and resolve disputes. The retention period is determined based on the nature of the information and the purposes for processing it.',
    },
    {
      title: "User Rights",
      content: 'You have the following rights regarding your personal information:',
      listWithTitle: [
        {
            title: 'Access',
            description: 'You can request access to your personal data.'
        },
        {
            title: 'Correction',
            description: 'You can request correction of any inaccurate data.'
        },
        {
            title: 'Deletion',
            description: 'You can request deletion of your data, subject to legal obligations.'
        },
        {
            title: 'Objection',
            description: 'You can object to the processing of your data.'
        },
        {
            title: 'Data Portability',
            description: 'You can request a copy of your data in a machine-readable format.'
        }
      ],
      footer: `To exercise your rights, please contact us at ${emailId}.`
    },
    {
      title: "International Data Transfers",
      content: 'If we transfer your personal information to other countries, we ensure appropriate safeguards are in place, such as standard contractual clauses, to protect your data.'
    },
    {
      title: "Third-Party Links",
      content: 'Our website may contain links to third-party websites. We are not responsible for the privacy practices or the content of these websites. We encourage you to read the privacy policies of any linked sites you visit.'
    },
    {
      title: "Children's Privacy",
      content: 'Our services are not intended for individuals under the age of 18. We do not knowingly collect personal information from children under 18. If we become aware that a child under 18 has provided us with personal information, we will take steps to delete such information.'
    },
    {
      title: "User Consent",
      content: 'By using our website and services, you consent to the collection and use of your personal information as described in this Privacy Policy. For sensitive personal data, we obtain explicit consent from you before processing.'
    },
    {
      title: "Data Anonymization and Aggregation",
      content: 'We may anonymize and aggregate personal information for analytical and research purposes. This aggregated data cannot be used to identify any individual and is used to improve our services and business practices.'
    },
    {
      title: "Complaint Handling",
      content: `If you have any complaints or concerns regarding our privacy practices, please contact us at ${emailId}. We will investigate and respond to your complaint promptly.`
    },
    {
      title: "Legal Obligations and Compliance",
      content: 'We comply with applicable data protection laws in India, including the Information Technology Act, 2000, and the Information Technology (Reasonable Security Practices and Procedures and Sensitive Personal Data or Information) Rules, 2011. We also comply with international data protection regulations where applicable.'
    },
    {
      title: "Changes to the Privacy Policy",
      content: `We may update this Privacy Policy from time to time. Any changes will be communicated to you through our website. The effective date of this Privacy Policy is ${effectiveDate}.`
    },
    {
      title: "Contact Information",
      content: 'If you have any questions or concerns about this Privacy Policy, please contact us at:',
      listWithTitle: [
        {
            title: 'Email',
            description: emailId
        },
        {
            title: 'Phone',
            description: phoneNumber
        }
      ],
      footer: 'DigitizeReal Technologies Private Limited, Registered in 2022, Delhi, India.'
    }
  ];
  
  export default privacyPolicyContent;
  