import { useNavigate, useLocation } from 'react-router-dom';

const NavigationLink = ({ to, label, target }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const scrollToElement = (elementId) => {
    const element = document.getElementById(elementId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };
  

  const handleClick = (event) => {
    const url = new URL(to, window.location.href);
    const pathname = url.pathname;
    const hash = url.hash.replace('#', '');

    // Check if the link is a hash link meant for the homepage
    if (hash && (location.pathname === '/' || location.pathname === pathname)) {
      event.preventDefault(); // Prevent default anchor behavior
      scrollToElement(hash); // Scroll to the element if already on the page
    } else {
      // Handle normal navigation for non-hash or off-homepage hash links
      navigate(to);
    }
  };

  return (
    <a href={to} onClick={handleClick} target={target}>
      {label}
    </a>
  );
};

export default NavigationLink;