import React from "react";
import "./Footer.scss";
import { footerData } from "../../constants/data";
import NavigationLink from "../NavigationLink/NavigationLink";

const Footer = () => {
  const { companyDetails, address, companyName, email, phone, year, otherLinks, otherMsg } = footerData;
  const { firstName,lastName, quote, extras } = companyDetails;
  const { line1, line2, city, state, pinCode } = address;

  return (
    <>
      <div className="main-footer-container">
        <div className="footer-container">
          <div className="company-name-tag-for-media-query">
            <div className="company-name-tag-container">
              <div className="comp-name-container">
                {firstName}<span>{lastName}</span>
              </div>
              <div className="tag-line-container">
                {quote}
              </div>
              <div className="BeBold">{extras}</div>
            </div>
          </div>
          <div className="other-details-container">
            <div className="middle-item-container-for-tab">
              <div className="address">
                <div className="heading">Address</div>
                <div className="visit-address">
                  {`${line1}, ${line2}, ${city}, `}
                  {`${state} - ${pinCode}`}
                </div>
                <div className="heading">Business Enquiry</div>
                <div className="business-enq">
                  <a href={`mailto:${email}`} target="_blank" rel="noopener noreferrer">{email}</a>
                  <br />
                  <a href={`https://wa.me/${phone.split("+")[1].split(" ")[1]}`} target="_blank" rel="noopener noreferrer">{phone}</a>
                </div>
              </div>
            </div>
            <div className="other-links-container">
              {otherLinks.map((item, index) => {
                return (
                  <div className="links-container" key={index}>
                    <div className="link-heading">{item.heading}</div>
                    <ul>
                      {item.links.map((link, index) => (<li key={index} className="list-link">
                        {/* <a target={link.target ? link.target : ""} href={link.link}><i style={{color: "crimson"}} className="fa-brands fa-instagram"></i>{link.label}</a> */}
                        <NavigationLink to={link.link} label={link.label} target={link.target ? link.target : ""} />
                      </li>))}
                    </ul>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
        <div className="other-msg-container">
            <hr className="hor-line" />
            <div>{year} © {companyName} | {otherMsg}</div>
          </div>
      </div>
    </>
  );
};

export default Footer;
