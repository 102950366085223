import React, { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/free-mode";
import "swiper/css/autoplay";
import { FreeMode, Autoplay, Navigation } from "swiper";
import "./Carousel.scss";
import { CardData } from "../../constants/CardData";
const Carousel = () => {
  const [pic, setPic] = useState();
  const [cardData, setCardData] = useState(CardData);
  const [deviceType, setDeviceType] = useState('laptop');

  const slidesPerView = {
    laptop: 5,
    tablet: 3,
    mobile: 1
  }

  const handleCardStatus = (id, type) => {
    const cardDataCopy = cardData.map((card) => ({ ...card, active: false }));
    const cardDataLength = cardDataCopy.length;
    if (type !== "hover") {
      if (deviceType === "laptop") {        // 5-15
        if (id > 7) {
          cardDataCopy[id - slidesPerView.laptop -2].active = true;
        } else if (id === 6) {
          cardDataCopy[cardDataLength-1].active = true;
        } else {
          cardDataCopy[0].active = true;
        }
      } else if (deviceType === "mobile") { //1-11
        if (id === 11) {
          cardDataCopy[0].active = true;
        } else {
          cardDataCopy[id-1].active = true;
        }
      } else if (deviceType === "tablet") {
        if (id > 12) {
          cardDataCopy[id - slidesPerView.tablet -1].active = true;
        } else if (id === 3) {
          cardDataCopy[0].active = true;
        } else {
          cardDataCopy[id-4].active = true;
        }
      }
    } else {
      cardDataCopy[id].active = true;
    }
    setCardData(cardDataCopy);
  };

  useEffect(() => {
    if (window.innerWidth > 0 && window.innerWidth <= 475) setDeviceType('mobile');
    else if (window.innerWidth > 0 && window.innerWidth <= 768) setDeviceType('tablet');
    else setDeviceType('laptop');
  }, []);

  useEffect(() => {
    const index = cardData.findIndex((item) => item.active);
    if (index !== -1)  {
      setPic(cardData[index].imgurls[deviceType]);
    }
  }, [cardData, deviceType]);

  return (
    <div className="mainContainer">
      <div className="back-image-slider">
        <img src={pic} alt="" />
      </div>
      <div className="upper-slider-container">
        <Swiper
          slidesPerView={5}
          spaceBetween={2}
          loop={true}
          centeredSlides={true}
          autoplay={{
            delay: 2000,
            disableOnInteraction: false,
            pauseOnMouseEnter: true,
          }}
          navigation={false}
          modules={[FreeMode, Autoplay, Navigation]}
          className="mySwiper"
          onSlideChange={(e) => {
            handleCardStatus(e.activeIndex);
          }}
          breakpoints={{
            320: {
              slidesPerView: 1,
              spaceBetween: 2,
            },
            475: {
              slidesPerView: 1,
              spaceBetween: 2,
            },
            768: {
              slidesPerView: 3,
              spaceBetween: 2,
            },
            1024: {
              slidesPerView: 5,
              spaceBetween: 2,
            },
          }}
        >
          {cardData.map((card) => (
            <SwiperSlide key={card.id}>
              <div
                className={card.active ? "box box-active" : "box"}
                onMouseEnter={() => handleCardStatus(card.id, "hover")}
              >
                <div className="parent">
                  <div
                    className={
                      card.active ? "headers headers-active" : "headers"
                    }
                  >
                    <h3>{card.heading}</h3>
                  </div>
                  {/* {card.active?   */}
                  <div
                    className={
                      card.active ? "content content-active" : "content"
                    }
                  >
                    <p>{card.description}</p>
                  </div> 
                  {/* :""}  */}
                
                  {/* <div
                    className={
                      card.active
                        ? "learn-more learn-more-active"
                        : "learn-more"
                    }
                  >
                    <button>{card.learnM}</button>
                  </div> */}
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

export default Carousel;
