import React, {useState} from "react";
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import "./Navbar.scss";

const navLinks = [
    // {
    //     title: "About Us",
    //     link: "/#about"
    // },
    {
        title: "Services",
        link: "/#services"
    },
    {
        title: "Industries",
        link: "/#industries"
    },
    {
        title: "Process",
        link: "/#process"
    },
    // {
    //     title: "Blogs",
    //     link: "/#blogs"
    // },
    {
        title: "Contact Us",
        link: "/#contact-us"
    }
]

function Navbar(props) {
    const [sticky, setSticky] = useState(false);
    const [showMenu, setShowMenu] = useState(true);

    const menuClickHandler = () => {
        showMenu ? setShowMenu(false) : setShowMenu(true);
    }

    const stickyOnScroll = () => {
        if (window.scrollY > 20) {
            setSticky(true);
        } else {
            setSticky(false);
        }
    }

    window.addEventListener('scroll', stickyOnScroll);

  return (
    <>
        <nav className={sticky ? "navbar sticky" : "navbar"}>
            <div className="max-width">
                <div className="logo">
                    <a href="#home">
                        <img src="logo.png" alt="logo"></img>
                        Digitize<span>Real</span>
                    </a></div>
                <ul className={showMenu ? "menu active" : "menu"}>
                    {navLinks.map(navItem => <li onClick={() => setShowMenu(true)} key={navItem.title}><a href={navItem.link}>{navItem.title}</a></li>)}
                </ul>
                <div className={showMenu ? "menu-btn" : "menu-btn active"} onClick={menuClickHandler}>
                    {showMenu ? <MenuIcon /> : <CloseIcon />}
                </div>
            </div>
        </nav>
    </>
  );
}

export default Navbar;
