import React from "react";
import "../CardContainer/card.scss";
import {CheckOutlined} from "@ant-design/icons";
import { Button } from "antd";
import { footerData } from "../../constants/data";

function PricingCard(props) {
  return (
    <>
      <div>
        <div className="card-container">
          <div className="card">
            {/* <div className="image-container">
              <img src={props.details.img} alt="Imae blog" className="imgBlog" />
            </div> */}
            <div className="main-container">
              <div className="descc">
                <div className="discription" style={{fontSize: '1rem', fontWeight: 'normal', color: 'crimson'}}>{props.details.plan}</div>
              </div>
              <div className="descc">
                <div className="discription" style={{fontSize: '1.8rem', fontWeight: 'bold'}}>{props.details.title}</div>
              </div>
              <div className="comment-container">
                <div className="comment">{props.details.description}</div>
              </div>
              {/* <div>Rs. {props.details.price}</div> */}
              <hr style={{margin: '0.6rem 0', color: 'grey'}} />
              <div style={{display: 'flex', justifyContent: 'flex-start', flexDirection: 'column', alignItems: 'flex-start', textAlign: 'left'}}>
                {props.details.services.map(service => (<p style={{marginBottom: '0.3rem'}}><CheckOutlined style={{color: 'crimson', fontSize: '1.1rem'}} /><span style={{fontSize: '0.8rem'}}>{service}</span></p>))}
              </div>
              <div style={{margin: '1rem 0', display: 'flex', justifyContent: 'space-between'}}>
                <Button danger type="link">Make Payment</Button>
                <Button danger type="primary"><a href={`https://wa.me/${footerData.phone.split("+")[1].split(" ")[1]}`} target="_blank" rel="noopener noreferrer">WhatsApp Us</a></Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PricingCard;
