export const listDummyData = [
  {
    number: 1,
    heading: "Analysis and Planning",
    content: "The first step to any profitable (tech) product is discovery – before creating your masterpiece, even the best team has to understand the overall project vision and scope, as well as the general needs of your stakeholders and users. We’ll take a deep dive into your business requirements including the tech stack, and market position to see where we stand."
  },
  {
    number: 2,
    heading: "Requirements",
    content: "The next step is to understand the technical requirements of this project. Every piece of software—whether it’s an app, website redesign, or new feature—needs to solve a customer problem."
  },
  {
    number: 3,
    heading: "Design and Prototyping",
    content: "Depending on the software development process you’re following, this phase we create simple wireframes to show how interactions will work in the software, or make more full-fledged prototypes using a tool like Marvel or InVision to test with users. Alternatively, you might decide you need more user feedback and do a design sprint to quickly get a feature or idea in front of your users."
  },
  {
    number: 4,
    heading: "Software Development",
    content: "The goal here is to stick to the plan, avoid scope creep, and build clean, efficient software"
  },
];

export const phoneNumber = "+91 8707361339";
export const emailId = "company@digitizereal.com";
export const effectiveDate = "May 20th, 2024";

export const services = [
  {
    label: "General Enquiry",
    value: "GE",
  },
  {
    label: "Application Development",
    value: "APPDEV",
  },
  {
    label: "Website Development",
    value: "WEBDEV",
  },
  {
    label: "Block Chain Development",
    value: "BCDEV",
  },
];

export const ServiceData = [

  {
    active: true,
    header: "Web Development",
    key: 'web',
    mainContent:
      "We create digital transformation roadmaps and implement technologies that become growth drivers for our customers’ businesses.",
    subContent: [
      {
        logo: "/Images/react.svg",
        title: "React Js"
      },
      {
        logo: "/Images/nextJs.png",
        title: "Next Js"
      },
      {
        logo: "/Images/angular.svg",
        title: "Angular"
      },
      {
        logo: "/Images/vue.png",
        title: "Vue Js"
      },
      {
        logo: "/Images/java.png",
        title: "Java"
      },
      {
        logo: "/Images/nodeJs.png",
        title: "Node Js"
      },
      {
        logo: "/Images/python.png",
        title: "Python"
      },
      {
        logo: "Images/laravel.png",
        title: "Laravel"
      }
    ],
  },
  {
    header: "App Development",
    key: 'app',
    mainContent:
      "Our software development company delivers corporate and consumer applications based on our profound understanding of technologies and the markets they operate in. With our professional mindset, we look beyond technology to offer viable solutions for your particular business context.",
    subContent: [
      {
        logo: "/Images/android.png",
        title: "Android"
      },
      {
        logo: "/Images/flutter.webp",
        title: "Flutter"
      },
      {
        logo: "/Images/react native.svg",
        title: "React Native"
      }
    ],
  },
  {
    header: "Blockchain Development",
    key: 'blockchain',
    mainContent:
      "We put together software ecosystems that enable end-to-end process automation, data-driven decision making, and productive collaboration based on growth-ready technologies.",
    subContent: [
      {
        logo: "/Images/ethereum.png",
        title: "Ethereum"
      },
      {
        logo: "/Images/solidity.svg",
        title: "Solidity"
      },
      {
        logo: "/Images/web3Js.webp",
        title: "Web3 Js"
      }
    ],
  },
  {
    header: "DevOps",
    key: 'devops',
    mainContent:
      'We offer DevOps (a portmanteau of "development" and "operations") services, which help organizations deliver applications and services faster than traditional software development processes. In addition, we provide support for various tools and are also focusing on automation.',
    subContent: [
      {
        logo: "/Images/aws.png",
        title: "AWS"
      },
      {
        logo: "/Images/linux.jpeg",
        title: "Linux"
      },
      {
        logo: "/Images/cicd.png",
        title: "CICD"
      },
      {
        logo: "/Images/jenkins.png",
        title: "Jenkins"
      },
      {
        logo: "/Images/git.png",
        title: "Git"
      },
      {
        logo: "/Images/atlassian.png",
        title: "Atlassian"
      },
      {
        logo: "/Images/docker.png",
        title: "Docker"
      },
      {
        logo: "/Images/kubernetes.png",
        title: "Kubernetes"
      }
    ],
  },
  {
    header: "Digital Marketing",
    key: 'dm',
    mainContent:[
      "Furthermore, we provide paid media marketing through the below mentioned platforms and tools. Paid Media marketing includes services such as",
      "Paid Search: Google Search & Microsoft (formerly Bing) Search Advertising Google Shopping Ads & Microsoft Shopping Ads",
      "Popular paid social platforms: Facebook, Instagram, LinkedIn, Snapchat, Google Video / YouTube Ads, Amazon Advertising"
    ],
    subContent: [
      {
        logo: "/Images/g-ads.png",
        title: "Google Ads"
      },
      {
        logo: "/Images/amazon-ads.png",
        title: "Amazon Ads"
      },
      {
        logo: "/Images/linkedin-ads.png",
        title: "LinkedIn Ads"
      },
      {
        logo: "/Images/facebook-ads.png",
        title: "Facebook Ads"
      },
      {
        logo: "/Images/insta-ads.jpeg",
        title: "Instagram Ads"
      },
      {
        logo: "/Images/snap-ads.png",
        title: "Snapchat Ads"
      }
    ],
  }
];

export const footerData = {
  companyDetails: {
    firstName: "Digitize",
    lastName: "Real",
    quote: "We digitize and automate the solutions to real world problems which are difficult to solve.",
    extras: ""
  },
  address: {
    line1: "V-228, Ground Floor",
    line2: "Block V, Sector 12",
    city: "Noida",
    state: "Uttar Pradesh",
    pinCode: "201301"
  },
  email: emailId,
  phone: phoneNumber,
  otherLinks: [
    {
      heading: "Explore",
      links: [
        // {
        //   label: "Home",
        //   link: "#home"
        // },
        {
          label: "Services",
          link: "/#services"
        },
        {
          label: "Industries",
          link: "/#industries"
        },
        {
          label: "Process",
          link: "/#process"
        },
        // {
        //   label: "Client Reviews",
        //   link: "#reviews"
        // },
        // {
        //   label: "Blogs",
        //   link: "#blogs"
        // },
        {
          label: "Contact Us",
          link: "/#contact-us"
        }
      ]
    },
    {
      heading: "Follow",
      links: [
        {
          label: "Instagram",
          link: "https://www.instagram.com/digitizereal",
          target: "_blank"
        },
        {
          label: "Linkedin",
          link: "https://www.linkedin.com/company/digitizereal",
          target: "_blank"
        },
        // {
        //   label: "Twitter",
        //   link: "https://twitter.com/digitize_real",
        //   target: "_blank"
        // }
      ]
    },
    {
      heading: "Legal",
      links: [
        {
          label: 'Disclaimer',
          link: "/disclaimer"
        },
        {
          label: 'Refund & Cancellation',
          link: '/refund'
        },
        {
          label: "Privacy Policy",
          link: "/privacy"
        },
        {
          label: "Terms of Use",
          link: "/terms-and-conditions"
        },
      ]
    }
  ],
  companyName: "DigitizeReal Technologies Pvt. Ltd.",
  year: "2024",
  otherMsg: "All Rights Reserved"
}

export const pricing = {
  web: [
    {
      id: 'basic',
      mainHeading: 'The Best Website Prices For You',
      subHeading: 'Join us and get tailored web development solutions to your business',
      plan: 'Basic',
      title: 'Website Development',
      price: '',
      description: 'Perfect for businesses wanting a strong online presence.',
      services: [
        'Free Domain',
        'Attractive Custome Design',
        'Mobile Responsive Page',
        'Full Ownership of the website',
        'WhatsApp & Social media Integration',
        'Google map Integration',
        'Inquiry form',
        'Free Hosting For 1 Year'
      ]
    },
    {
      id: 'store',
      mainHeading: 'The Best Website Prices For You',
      subHeading: 'Join us and get tailored web development solutions to your business',
      plan: 'Online Store',
      title: 'E-commerce Website',
      description: 'Integrating e-commerce features to expand business’s online presence.',
      price: '',
      services: [
        'Free Domain',
        'Payment Gateway Integration',
        'Attractive Custome Design',
        'Mobile Responsive Page',
        'Full Ownership of the website',
        'WhatsApp & Social media Integration',
        'Google map Integration',
        'Inquiry form',
        'Unlimited Products',
        'Free Hosting For 1 Year'
      ]
    },
    {
      id: 'gold',
      mainHeading: 'The Best Website Prices For You',
      subHeading: 'Join us and get tailored web development solutions to your business',
      plan: 'Gold',
      title: 'Customized Website',
      description: 'Your business is one of a kind. Your website should be too.',
      price: '',
      services: [
        'Free Domain',
        'UI/UX Design',
        'Mobile Responsive Page',
        'Full Ownership of the website',
        'WhatsApp & Social media Integration',
        'Google map Integration',
        'Inquiry form',
        'Customized Pages'
      ]
    }
  ],
  app: [
    {
      id: 'basic',
      mainHeading: 'The Best App Development Prices For You',
      subHeading: 'Join us and get tailored Mobile App development solutions to your business',
      plan: 'Basic',
      title: 'Application',
      price: '',
      description: '',
      services: [
        'Popup',
        'Coupon',
        'Home page',
        'Menu/Shop page',
        'Single item page',
        'About us page',
        'Support page',
        'My Orders page',
        'My Profile page',
        'Address page',
        'Privacy Policy page',
        'Terms & Conditions page'
      ]
    },
    {
      id: 'gold',
      mainHeading: 'The Best App Development Prices For You',
      subHeading: 'Join us and get tailored Mobile App development solutions to your business',
      plan: 'Gold',
      title: 'Customized',
      price: '',
      description: '',
      services: [
        'Customized features',
        'Customized Pages',
        'Home page',
        'Menu/Shop page',
        'Single item page',
        'About us page',
        'Support page',
        'My Orders page',
        'My Profile page',
        'Address page',
        'Privacy Policy page',
        'Terms & Conditions page'
      ]
    }
  ],
  dm: [
    {
      id: 'basic',
      mainHeading: 'The Best Digital Marketing Prices For You',
      subHeading: 'Join us and get tailored digital marketing solutions to your business',
      plan: 'Basic',
      title: 'Package',
      price: '',
      description: '',
      services: [
        '2 social media platforms',
        'Content creation',
        '8 Posts',
        '8 Stories',
        '4 instagram highlights'
      ]
    },
    {
      id: 'standard',
      mainHeading: 'The Best Digital Marketing Prices For You',
      subHeading: 'Join us and get tailored digital marketing solutions to your business',
      plan: 'Standard',
      title: 'Package',
      price: '',
      description: '',
      services: [
        '4 social media platforms',
        'Content creation',
        '12 Posts',
        '12 Stories',
        '6 instagram highlights'
      ]
    },
    {
      id: 'premium',
      mainHeading: 'The Best Digital Marketing Prices For You',
      subHeading: 'Join us and get tailored digital marketing solutions to your business',
      plan: 'Premium',
      title: 'Package',
      price: '',
      description: '',
      services: [
        '6 social media platforms',
        'Content creation',
        '16 Posts',
        '16 Stories',
        '8 instagram highlights'
      ]
    }
  ]
}