import React from "react";
import { footerData } from "../../constants/data";
import "./BGvideoComp.scss";

function BGvideoComp(props) {
  return (
    <>
      <video autoPlay loop muted playsInline className="back-video">
        <source src="videos/video1.mp4" type="video/mp4" />
        <img src="Images/tech1.png" alt="alt technology" />
      </video>
      <div className="video-text-content">
        <h3>{footerData.companyDetails.quote}</h3>
      </div>
    </>
  );
}

export default BGvideoComp;
