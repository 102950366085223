import React, { useState } from "react";
import "./Services.scss";
import { ServiceData } from "../../constants/data";
import ServiceDetails from "./ServiceDetails";

const Services = () => {
  const [servicesData, setServicesData] = useState(ServiceData);
  const [activeIndex, setActiveIndex] = useState(0);
  const onHandleClick = (index) => {
    const serviceDataCopy = servicesData.map((elm) => ({
      ...elm,
      active: false,
    }));
    serviceDataCopy[index].active = true;
    setServicesData(serviceDataCopy);
    setActiveIndex(index);
  };
  return (
    <>
      <div className="services-container">
        <div className="heading-container">
          <div className="ourserivce">Our Services</div>
          <div className="heading-tabs-container">
            {servicesData.map((curelm, index) => {
              return (
                <div key={index}
                  onClick={() => {
                    onHandleClick(index);
                  }}
                  className={curelm.active ? "headings active" : "headings"}
                >
                  {curelm.header}
                </div>
              );
            })}
          </div>
        </div>
        <div
          className="content-container"
          style={{ display: "flex", flexDirection: "column" }}
        >
          <ServiceDetails details={servicesData[activeIndex]} />
        </div>
      </div>
    </>
  );
};

export default Services;
