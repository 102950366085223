import React, { useState } from "react";
import "./ServiceDetails.scss";
import CardWithImg from "../common/CardWithImg/CardWithImg";
import { Button, Modal } from 'antd';
import PricingCard from "../PricingCard/PricingCard";
import { pricing, footerData } from "../../constants/data";

const ServiceDetails = (props) => {
    const {details} = props;
    const [modalOpen, setModalOpen] = useState(false);
    const [modalFor, setModalFor] = useState('web');

    const getMainContent = (content) => {
      if (typeof(content) === "string") {
        return <p>{content}</p>
      } else {
        return content.map((item, index) => <p key={index} style={{marginBottom: "8px", marginLeft: index === 0 ? "0px": "2rem"}}>{index !== 0 && '-'} {item}</p>)
      }
    }

  return (
    <>
      <div className="title-container-box">
        <h1>{details.header}</h1>
      </div>
      <div className="detail-container">
        {getMainContent(details.mainContent)}
      </div>
      <div className="tech-container">
        {
            details.subContent.map((subitem, index) => <CardWithImg key={index} title={subitem.title} logo={subitem.logo} />)
        }
      </div>
      <div>
        {['web', 'app', 'dm'].includes(details.key) 
          ? <Button type="primary" danger onClick={() => {setModalOpen(true); setModalFor(details.key)}}>The Best {details.key === 'web' ? 'Website' : details.key === 'app' ? 'App Development' : 'Digital Marketing'} Prices For You</Button> 
          : <Button danger type="primary"><a href={`https://wa.me/${footerData.phone.split("+")[1].split(" ")[1]}`} target="_blank" rel="noopener noreferrer">WhatsApp Us</a></Button>
        }
        <Modal
          title=""
          centered
          open={modalOpen}
          onOk={() => setModalOpen(false)}
          onCancel={() => setModalOpen(false)}
          width={modalFor === 'app' ? 670 : 1000}
          footer={null}
        >
          <div style={{margin: 'auto', textAlign: 'center', marginBottom: '0rem'}}>
            <h2>{pricing[modalFor][0].mainHeading}</h2>
            <p>{pricing[modalFor][0].subHeading}</p>
          </div>
          <div style={{display: 'flex', justifyContent: 'center', flexDirection: window.innerWidth > 450 ? 'row' : 'column'}}>
            {pricing[modalFor].map((card) => (
              <PricingCard key={card.id} details={card} />
            ))}
          </div>
          <i style={{fontSize: '0.8rem', color: 'grey'}}>* Make an initial payment of Rs. 20,000 and contact us through WhatsApp to proceed.</i>
        </Modal>
      </div>
    </>
  );
};

export default ServiceDetails;
