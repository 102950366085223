const returnAndCancellationPolicy = [
    {
      title: "Cancellation Policy",
      content: `At DigitizeReal Technologies Private Limited, we understand that plans can change, and we strive to accommodate those changes as best as we can. Clients may cancel their service agreement within 24 hours of booking without any penalty. For cancellations made after 24 hours of booking, we may charge a cancellation fee that will depend on the specifics of the service booked and how much work has already been completed. To cancel your service, please contact us immediately at support@digitizereal.com with your service details so that we can process your request promptly.`
    },
    {
      title: "Refund Policy",
      content: `Refunds for cancelled services are processed based on the completion stage of the work at the time of cancellation. If cancellation occurs before work has begun, you will receive a full refund. If work has already started, a partial refund will be issued, which will be proportional to the work completed. All refunds will be processed to the original method of payment within 7 to 10 business days.`
    },
    {
      title: "Changes to Booked Services",
      content: `We allow changes to the scope of booked services if requested at least 48 hours before the scheduled start time. Any requests for changes should be made via email to support@digitizereal.com. Please note that changes may be subject to additional charges if they extend the scope or duration of the originally booked services.`
    },
    {
      title: "No-Show Policy",
      content: `A no-show by a client for a scheduled service appointment will result in a forfeiture of any deposit paid. No-shows also risk cancellation of their service agreement without a refund, depending on the terms specified at the time of booking.`
    },
    {
      title: "Late Payments",
      content: `Late payments for services rendered may incur late fees. Details regarding late payment fees will be specified in the service agreement. We encourage clients to adhere to the agreed payment schedule to avoid any additional charges.`
    },
    {
      title: "Quality Guarantee",
      content: `DigitizeReal Technologies Private Limited stands behind the quality of its services. If you are not satisfied with the service received, please contact us within 24 hours of the service completion. We will review your concerns and strive to adjust the service to meet your expectations, subject to the terms and conditions of the service agreement.`
    }
  ];
  
  export default returnAndCancellationPolicy;
  