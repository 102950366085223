import React from "react";
import "./CardWithImg.scss";
const CardWithImg = (props) => {
  const { title, logo } = props;
  return (
    <div className="logo-card-container">
      <div className="logo-container">
        <img src={logo} alt={title} className="logo-img" />
      </div>
      <div className="logo-title">{title}</div>
    </div>
  );
};

export default CardWithImg;
